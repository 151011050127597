@import "../../styles/mixin";
@import "../../styles/variables";

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-size: 300% 300%;
  backdrop-filter: blur(1rem);
  border-radius: 5rem;
  transition: 0.5s;
  animation: gradient_301 15s ease infinite;
  border: double 4px transparent;
  background-image: linear-gradient(#FCC0BF, #FCC0BF),
    linear-gradient(137.48deg, #ffdb3b 10%, #FE53BB 45%, #8F51EA 67%, #0044ff 87%);
  background-origin: border-box;
  background-clip: content-box, border-box;


  position: absolute;
  top: 82%;
  left: 30%;

  @include desktop {
    top: 88.5%;
    left: 7%;
  }

  width: 170px;
  height: 50px;
}

#container-stars {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: 0.5s;
  backdrop-filter: blur(1rem);
  border-radius: 5rem;
}

strong {
  z-index: 2;
  font-family: 'Avalors Personal Use';
  font-size: 12px;
  letter-spacing: 5px;
  color: black;
  text-shadow: 0 0 4px white;
}

#glow {
  position: absolute;
  display: flex;
  width: 12rem;
}

.circle {
  width: 100%;
  height: 30px;
  filter: blur(2rem);
  animation: pulse_3011 4s infinite;
  z-index: -1;
}

.circle:nth-of-type(1) {
  background: rgba(254, 83, 186, 0.636);
}

.circle:nth-of-type(2) {
  background: rgba(142, 81, 234, 0.704);
}

.btn:hover #container-stars {
  z-index: 1;
  background-color: #FCC0BF;
}

.btn:hover {
  transform: scale(1.1)
}

.btn:active {
  border: double 4px #FE53BB;
  background-origin: border-box;
  background-clip: content-box, border-box;
  animation: none;
}

.btn:active .circle {
  background: #FE53BB;
}

#stars {
  position: relative;
  background: transparent;
  width: 200rem;
  height: 200rem;
}

#stars::after {
  content: "";
  position: absolute;
  top: -10rem;
  left: -100rem;
  width: 100%;
  height: 100%;
  animation: animStarRotate 90s linear infinite;
}

#stars::after {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
}

#stars::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 170%;
  height: 500%;
  animation: animStar 60s linear infinite;
}

#stars::before {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
  opacity: 0.5;
}

@keyframes animStar {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-135rem);
  }
}

@keyframes animStarRotate {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0);
  }
}

@keyframes gradient_301 {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulse_3011 {
  0% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}


.HomePage {

  &_phone {
    position: absolute;
    top: 25%;
    left: 8%;
    margin: 0;
    font-size: 1.2em;
    font-weight: 700;
    letter-spacing: 0.1em;
  }

  &_right-logo {
    background: url("../../Assets/DreamyDates.svg") no-repeat center center;
    background-size: contain;
    position: absolute;
    height: 300px;
    width: 350px;
    top: 56%;
    right: 7%;

    @include desktop {
      height: 400px;
      width: 500px;
    }
  }

  &_right-text {
    color: black;
    position: absolute;
    top: 90.5%;
    right: 9%;
    width: 385px;
    height: 130px;
    border-radius: 300px/100px;
    cursor: pointer;
  }

  &_right-text p {
    margin: 0;
    font-size: 0.7em;
    font-weight: 500;
    letter-spacing: 0.1em;
    padding-left: 9%;

    @include desktop {
    font-size: 0.9em;
    font-weight: 500;
    padding-left: 0%;
    }
  }
}








@keyframes rainDrop {
  0% {
    top: -10%;
    /* Start above the viewport */
    opacity: 0;
    /* Fully transparent at the start */
  }

  10% {
    opacity: 1;
    /* Become fully opaque shortly after starting */
  }

  100% {
    top: 110%;
    /* Move to below the viewport */
    opacity: 0;
    /* Become fully transparent again */
  }
}

.rain-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
  z-index: -1;
}

.rain-drop {
  position: absolute;
  width: 2px;
  /* Narrow width to resemble raindrop */
  height: 10px;
  /* Short height */
  background-color: rgba(255, 255, 255, 0.6);
  /* Semi-transparent white */
  animation: rainDrop 2s linear infinite;
  /* Apply the rainDrop animation */
  opacity: 0;
  /* Start fully transparent */
}


.rain-drop:nth-child(2n) {
  animation-duration: 2.5s;
}

.rain-drop:nth-child(3n) {
  animation-duration: 2.8s;
}



.footer-part {
  background-color: #FAE0DF;
}