.flowers {
    padding: 40px;
    text-align: center;

    &__title {
        font-size: 36px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
    }
}

.flower-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    padding: 20px;
    width: 400px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 16px rgba(0,0,0,0.2);
    }

    &__img img {
        max-width: 100%;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    &__name {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 5px;
    }

    &__rating {
        font-size: 14px;
        color: #888;
        margin-bottom: 5px;

        &-star {
            color: #f39c12;
        }
    }

    &__price {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    &__button {
        background-color: #FCC0BF;
        color: #fff;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;

        &:hover {
            background-color: #B9EBE9;
            box-shadow: 0 0 20px #6fc5ff50;
            transform: scale(1.1);
        }
        &__button:active {
            background-color: #efc2c1;
            transition: all 0.25s;
            -webkit-transition: all 0.25s;
            box-shadow: none;
            transform: scale(0.98);
        }
    }
}

.flowers-page {
    background-color: #FAE0DF;
}
