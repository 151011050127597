.feedback-form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  &__form {
    position: relative;
    background: #B9EBE9;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    max-width: 600px;
    width: 100%;
  }

  &__close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }

  &__title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }

  &__label {
    font-size: 16px;
    margin: 10px 0;
  }

  &__input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  &__textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 20px;
  }

  &__btn-block {
    text-align: center;
  }

  &__button {
    background-color: #FCC0BF;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }

  &__button:hover {
    background-color: #B9EBE9;
    box-shadow: 0 0 20px #6fc5ff50;
    transform: scale(1.1);
  }

  &__button:active {
    background-color: #efc2c1;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
    box-shadow: none;
    transform: scale(0.98);
  }
  
  &__rating {
    margin-bottom: 20px;
    text-align: center;
  }
}
