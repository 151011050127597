.contact {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 0 40px;
  position: relative;
  z-index: 1;
  flex-wrap: wrap;

  @media (max-width: 768px) {
gap: 10px;
  }

  &__info {
    flex: 1;
    padding: 20px;
    background-color: #FCC0BF;
    color: #fff;
    border-radius: 8px;
    margin-right: 20px;
    max-width: 400px;
    min-width: 200px;

    @media (max-width: 768px) {
      height: 200px;
      width: 150px;
      padding: 10px;
    }

    h1 {
      font-size: 32px;
      margin-bottom: 10px;

      @media (max-width: 768px) {
        font-size: 18px;
        margin-bottom: 5px;
      }
    }

    p {
      font-size: 16px;
      margin-bottom: 20px;
      @media (max-width: 768px) {
        font-size: 12px;
        margin-bottom: 10px;
      }
    }

    &__details {
      p {
        display: flex;
        align-items: center;
        font-size: 16px;
        margin-bottom: 10px;

        img {
          margin-right: 10px;
        }
      }
    }
  }

  &__form {
    flex: 2;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    h2 {
      font-size: 24px;
      margin-bottom: 20px;
    }

    form {
      display: flex;
      flex-wrap: wrap;

      .form-group {
        flex: 1 1 100%;
        margin-bottom: 15px;

        label {
          display: block;
          font-size: 14px;
          margin-bottom: 5px;
        }

        input, textarea {
          width: 100%;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }

        textarea {
          resize: vertical;
          height: 100px;
        }
      }

      .form-group.half-width {
        flex: 1 1 48%;
        margin-right: 2%;

        &:nth-child(even) {
          margin-right: 0;
        }
      }

      .contact__form-button {
        padding: 10px 20px;
        background-color: #FCC0BF;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;

        &:hover {
          background-color: #B9EBE9;
          box-shadow: 0 0 20px #6fc5ff50;
          transform: scale(1.1);
        }

        &:active {
          background-color: #efc2c1;
          transition: all 0.25s;
          -webkit-transition: all 0.25s;
          box-shadow: none;
          transform: scale(0.98);
      }
      }
    }
  }
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;

  &__video {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 768px) {
    &__video {
      display: none;
    }
  }
}
