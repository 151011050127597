.category-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px; 
  padding: 20px; 
}

.category-card {
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s; 

  &:hover {
    transform: scale(1.05); 

    .category-image {
      border-radius: 50%; 
    }
  }
}

.category-image {
  width: 300px; 
  height: 500px;
  object-fit: cover;
  border-radius: 10px; 
  transition: border-radius 0.2s; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.category-caption {
  margin-top: 10px;
  font-size: 1rem;
  color: #000; 
  border-top: 2px solid #f8d7da; 
  padding-top: 5px;
}
