.reviews-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .reviews-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .reviews-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .rating-summary {
    display: flex;
    align-items: center;
  }
  
  .google-logo {
    height: 40px;
    margin-right: 10px;
  }
  
  .rating-score {
    font-size: 36px;
    font-weight: bold;
  }
  
  .rating-stars {
    font-size: 18px;
    color: #ffb400;
  }
  
  .rating-reviews {
    font-size: 14px;
    color: #777;
  }
  
  .review-button {
    background-color: #FCC0BF;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    
    @media (max-width: 768px) {
      padding: 10px 5px;
    }
    
  }

  .review-button:hover {

    background-color: #B9EBE9;
    box-shadow: 0 0 20px #6fc5ff50;
    transform: scale(1.1);
}

.review-button:active {
    background-color: #efc2c1;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
    box-shadow: none;
    transform: scale(0.98);
}
  
  .reviews-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
  
  .review-card {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    width: 30%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    min-width: 200px;
  }
  
  .review-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .review-name {
    font-weight: bold;
  }
  
  .review-rating {
    color: #ffb400;
  }
  
  .review-time {
    font-size: 12px;
    color: #777;
  }
  
  .review-text {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .review-footer {
    display: flex;
    align-items: center;
  }
  
  .google-logo-small {
    height: 20px;
    margin-right: 5px;
  }
  
  .review-platform {
    font-size: 12px;
    color: #777;
  }
  