@mixin mobile {
    @media (max-width: $breakpoint-mobile) {
      @content;
    }
  }
  
  @mixin tablet {
    @media (min-width: $breakpoint-tablet) {
      @content;
    }
  }
  
  @mixin desktop {
    @media (min-width: $breakpoint-desktop) {
      @content;
    }
  }
  
  
  
    
  
  